import React, { forwardRef, useImperativeHandle, useState } from 'react';
import useEventListener from '@use-it/event-listener'
import { getImage } from 'gatsby-plugin-image';
import { ArrowLeft, ArrowRight, ModalBS, ModalImageWrapper } from '../elements';


const ARROW_LEFT = ['ArrowLeft', 'ArrowLeft']
const ARROW_RIGHT = ['ArrowRight', 'ArrowRight']


export const Modal = forwardRef((props, ref) => {

    const [modalIndex, setModalIndex] = useState(false)

    const modalImage = props.nodes[modalIndex]

    const handlePrevious = () => {
        const isFirstIndex = modalIndex === 0;
        const newIndex = isFirstIndex ? props.nodes.length - 1 : modalIndex - 1;
        setModalIndex(newIndex);
    }
    const handleNext = () => {
        const isLastIndex = modalIndex === props.nodes.length - 1;
        const newIndex = isLastIndex ? 0 : modalIndex + 1;
        setModalIndex(newIndex);
    }

    useImperativeHandle(
        ref,
        () => ({
            setModal(i) {
                setModalIndex(i)
            }
        }),
    )

    function handler({ key }) {
        if (ARROW_LEFT.includes(String(key))) {
            handlePrevious();
        }
        if (ARROW_RIGHT.includes(String(key))) {
            handleNext();
        }
    }
    useEventListener('keydown', handler);

    return (
        <ModalBS {...props}>
            <ModalBS.Body>
                {modalImage &&
                    <ModalImageWrapper
                        objectFit='contain'
                        image={getImage(modalImage)}
                        alt={modalImage.name}
                    />}
            </ModalBS.Body>
            <ArrowLeft onClick={handlePrevious}>❮</ArrowLeft>
            <ArrowRight onClick={handleNext}>❯</ArrowRight>
        </ModalBS>
    )
})