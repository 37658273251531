import React from 'react';
import { FloatingLabel, Form, Row } from 'react-bootstrap';
import { BtnOutlinedDark } from '../elements';


export function ContactForm() {


    return (
        <Row className='align-items-center h-100' style={{ maxWidth: '600px' }}>
            <Form 
                name="contact-form" 
                method="POST" 
                data-netlify="true" 
                // data-netlify-recaptcha="true"
                data-netlify-honeypot="bot-filed"
            >
                <input type="hidden" name="form-name" value="contact-form" />
                <input type="hidden" name="subject" value="%{siteName} %{formName}" />
            {/* honeypot */}
            <p hidden><label>Spam input<input name='bot-field' /></label></p>
                <FloatingLabel className='mb-2 text-secondary' controlId='name' label='Name'>
                    <Form.Control
                        type="text"
                        name="name"
                        placeholder="Name"
                        required />
                </FloatingLabel>
                <FloatingLabel className='mb-2 text-secondary' controlId='telephone' label='Phone Number'>
                    <Form.Control
                        type="number"
                        name="telephone"
                        placeholder="Phone Number" />
                </FloatingLabel>
                <FloatingLabel className='mb-2 text-secondary' controlId='email' label='Email'>
                    <Form.Control
                        type="email"
                        name="email"
                        placeholder="Email"
                        required />
                </FloatingLabel>
                <FloatingLabel className='mb-2 text-secondary' controlId='message' label='Message'>
                    <Form.Control
                        as="textarea"
                        name="message"
                        placeholder="Message"
                        style={{ height: '200px' }}
                        required />
                </FloatingLabel>
                {/* <div data-netlify-recaptcha="true"></div> */}
                <BtnOutlinedDark className='w-100' type="submit" text="Send" />
            </Form>
        </Row>
    )
};