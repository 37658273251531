import React from 'react';
import { Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Col, Container, Row, Stack } from 'react-bootstrap';
import { TiTick } from 'react-icons/ti'
import { BtnOutlinedLight } from '../elements';


export function Service({ service: { frontmatter, html }, site_name }) {

    const { featuredImage, title, types } = frontmatter
    const image = getImage(featuredImage)

    // console.log(types)
    const serviceTypes = () => (types.split(', ')).map((type, i) =>
        <Stack direction="horizontal" gap={2} key={i}>
            <TiTick className='fs-3' style={{ color: 'green' }} />
            <div className='text-start'>{type}</div>
        </Stack>
    )

    // const subServicesList = () =>
    //     subServices.map((type, i) =>
    //         <div key={i}>
    //             <TiTick style={{ color: 'green', fontSize: '1.5rem' }} />
    //             <div>{type}</div>
    //         </div>
    //     )

    return (
        <Container className='position-relative' style={{ zIndex: 1 }} id='service-container' fluid>
            <Row xs={1} lg={2} className='py-5 justify-content-center'>
                <Col xs={10} lg={4} className='d-flex m-auto'>
                    <GatsbyImage image={image} style={{ width: '100%' }} alt={title} />
                </Col>
                <Col lg={7} className='d-flex flex-column justify-content-center py-4 fs-5'>
                    <Row>
                        <h2 className='text-capitalize'>
                            <strong>{title}</strong>
                        </h2>
                    </Row>
                    <Row>
                        <div dangerouslySetInnerHTML={{ __html: html }} />
                    </Row>
                    <Row xs={1} sm={2}>
                        <Col className='p-3'>
                            <Row>
                                <h5>
                                    <strong>{site_name} offers:</strong>
                                </h5>
                            </Row>
                            <Row>
                                <Container style={{ width: '260px' }}>
                                    <Stack gap={1}>
                                        {serviceTypes()}
                                    </Stack>
                                </Container>
                            </Row>
                        </Col>
                        <Col className='d-flex p-3'>
                            <Link to='/projects#projects' className='m-auto' state={{ filter: title }}>
                                <BtnOutlinedLight text={`view our previous projects`} />
                            </Link>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    )
};
