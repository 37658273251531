import { GatsbyImage } from 'gatsby-plugin-image';
import { Modal } from 'react-bootstrap';
import styled from "styled-components";
import media from 'styled-media-query';


export const ModalBS = styled(Modal).attrs({
    backdropClassName: 'modal-backdrop-background',
    contentClassName: 'modal-content-border w-auto',
    dialogClassName: 'justify-content-center m-auto',
})`
    padding-left: 0;
    ${media.greaterThan('large')`
        --bs-modal-width: calc(1100px);
    `}
`;
export const ModalImageWrapper = styled(GatsbyImage)`
    max-height: calc(100vh - var(--bs-modal-margin) * 2);
`;
export const Arrow = styled.div`
    position: fixed;
    top: 50%;
    transform: translate(0, -50%);
    font-size: 4rem;
    color: ${({ theme }) => theme.color.white};
    opacity: .5;
    cursor: pointer;
    :hover {
        opacity: 1;
    }
`;
export const ArrowLeft = styled(Arrow)`
    left: 32px;
`;
export const ArrowRight = styled(Arrow)`
    right: 32px;
`;