import React from 'react';
import { Header, Footer } from '.';
import { LayoutWrapper, LoaderLogo, LoaderText, LoaderWrapper } from '../elements';
import { graphql, useStaticQuery } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import { Col, Container, Row } from 'react-bootstrap';


export function Layout({ children, location }) {

  const { file } = useStaticQuery(graphql`
    query MyQuery {
      file(name: {eq: "Icon_Transparent"}) {
        childImageSharp {
          gatsbyImageData(width: 150, placeholder: NONE)
        }
      }
    }
  `);

  const image = getImage(file)
  // console.log(children)

  return (
    <LayoutWrapper>
      <LoaderWrapper>
        <Container className='d-flex justify-content-center align-items-center h-100'>
          <Row>
            <Col>
                <LoaderLogo image={image} alt='Logo' />
                <div className='d-grid lead text-uppercase fw-bold' style={{ marginTop: '-20px' }}>
                  <LoaderText className='h2'>Edinburgh Extensions</LoaderText>
                  <LoaderText className='h6'>over 30 years of experience</LoaderText>
                </div>
            </Col>
          </Row>
        </Container>
      </LoaderWrapper>
      <Header location={location} />
      {children}
      <Footer location={location} />
    </LayoutWrapper>
  )
};