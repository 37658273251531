import { createGlobalStyle } from "styled-components";


export const theme = {
    color: {
        black: '#000000',
        brownish_orange: '#E37627',
        dark_blue: '#1C3168',
        // dark_blue: '#284389', // original
        dark_gray: '#3e3e49',
        gray: '#808080',
        hover: '#FAFAD2',
        lightgray: '#d3d3d3',
        orange: '#FC832B',
        social_icons: '#c32aa3',
        transparent_dark_blue: 'rgba(28,49,104,0.2)',
        transparent_orange: 'rgba(252,131,43,0.2)',
        white: '#ffffff',
    },
    fonts: {
        header: 'Montserrat',
        copy: 'Source Sans Pro',
    },
    gradient: {
        brownish_orange: 'linear-gradient(200deg, rgba(227, 118, 39, 0), rgba(227, 118, 39, 0), rgba(227, 118, 39, 1) 80%)',
        dark_blue: 'linear-gradient(200deg, rgba(28, 49, 104, 0), rgba(28, 49, 104, 0), rgba(28, 49, 104, 1) 80%)',
        square_pattern: 'conic-gradient(from 116.56deg at calc(100%/3) 0,#0000 90deg,#1C3168 0), conic-gradient(from -63.44deg at calc(200%/3) 100%,#0000 90deg,#1C3168 0) #FC832B',
        cubic_pattern: 'conic-gradient(from -60deg at 50% calc(100%/3), #d9d9d9 0 120deg,#0000 0), conic-gradient(from 120deg at 50% calc(200%/3), #d9d9d9 0 120deg,#0000 0), conic-gradient(from  60deg at calc(200%/3), #d9d9d9 60deg, #ffffff 0 120deg,#0000 0), conic-gradient(from 180deg at calc(100%/3), #b2b2b2 60deg, #d9d9d9 0 120deg,#0000 0), linear-gradient(90deg, #b2b2b2 calc(100%/6), #ffffff 0 50%, #b2b2b2 0 calc(500%/6), #ffffff 0)'
    },
};


export const GlobalStyle = createGlobalStyle`
    body {
        background: ${({ theme }) => theme.color.white};
        border: 0;
        color: ${({ theme }) => theme.color.black};
        font-family: ${({ theme }) => theme.fonts.copy}, sans-serif;
        margin: 0;
        padding: 0;
    }
    header {

    }
    main {
        display: grid;
        /* grid-gap: 50px; */
        text-align: center;
    }
    footer {
        /* color: ${({ theme }) => theme.color.white}; */
        background-color: ${({ theme }) => theme.color.black};
    }
    nav {
        display: flex;
        justify-content: center;
        padding: 0;
        height: 16vh;
        gap: 10px;
        background: ${({ theme }) => theme.color.dark_blue};
    }
    section {
        width: 100%;
        padding-block: 2rem;
    }
    h1, h2, h3, h4 {
        font-family: ${({ theme }) => theme.fonts.header}, sans-serif;
        margin-bottom: 1rem;
        a &:hover {
            color: ${({ theme }) => theme.color.brownish_orange};
        }
    }
    ul {
        margin: 0;
        padding: 0;
    }
    a {
        text-decoration: none;
        color: ${({ theme }) => theme.color.orange};
        :hover {
            color: ${({ theme }) => theme.color.hover};
        }
    }
    li {
        text-align: initial;
    }
    li a {
        color: ${({ theme }) => theme.color.orange};
        :hover {
            color: ${({ theme }) => theme.color.hover};
        }
    }
    p a {
        color: ${({ theme }) => theme.color.brownish_orange};
        :hover {
            color: ${({ theme }) => theme.color.dark_blue};
        }
    }
    button {
        
    }
    .modal-backdrop-background {
        --bs-backdrop-opacity: 0.9;
    }
    .modal-content-border {
        --bs-modal-border-width: 0;
    }
`;