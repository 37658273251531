import React from "react"
import { useSiteMetadata } from "../hooks/use-site-metadata"


export const Seo = ({ author, title, description, pathname, children }) => {
  const { title: defaultTitle, author: defaultAuthor, contact, description: defaultDescription, site_name, siteUrl } = useSiteMetadata()

  const seo = {
    title: title || defaultTitle,
    site_name: site_name,
    description: description || defaultDescription,
    author: author || defaultAuthor,
    location: contact.city,
    email: contact.email,
    tel: contact.phone_1,
    // image: `${siteUrl}${image}`,
    url: `${siteUrl}${pathname || ``}`,
  }
  // console.log(seo.site_name)
  return (
    <>
      <title>{seo.title}</title>
      <meta name="title" content={seo.site_name} />
      <meta name="site_name" content={seo.site_name} />
      <meta name="description" content={seo.description} />
      <meta name="author" content={seo.author} />
      <meta name="location" content={seo.location} />
      <meta name="email" content={seo.email} />
      <meta name="tel" content={seo.tel} />
      <meta name="url" content={seo.url} />
      {/* <link rel="icon" href="data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100'><text y='0.9em' font-size='90'>👤</text></svg>" /> */}
      {children}
    </>
  )
}