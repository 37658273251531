import { Carousel, Col, Container } from "react-bootstrap";
import styled from "styled-components";
import media from "styled-media-query";

export const CollageImageCover = styled(Container)`
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: capitalize;
    font-size: 6vw;
    opacity: 0.6;
    @media (min-width: 576px) { font-size: 2rem; };
    @media (min-width: 992px) { font-size: 1.6rem; opacity: 0; };
    @media (min-width: 1200px) { font-size: 1.8rem; };
    @media (min-width: 1400px) { font-size: 2.2rem; };
    padding: 0.4em 0.8em;
    height: 100%;
    color: ${({ theme }) => theme.color.white};
    background-color: ${({ theme }) => theme.color.dark_blue};
    transition: .5s ease;
    :hover {
        opacity: .9;
    }
`;
export const ServiceContainer = styled(Col)`
    &:nth-child(odd) a .cover {
        background-color: ${({ theme }) => theme.color.dark_blue};
    }
    &:nth-child(even) a .cover {
        background-color: ${({ theme }) => theme.color.orange};
    }
    @media screen and (min-width: 576px) {
        &:nth-child(3) a .cover {
            background-color: ${({ theme }) => theme.color.orange};
        }
        &:nth-child(4) a .cover {
            background-color: ${({ theme }) => theme.color.dark_blue};
        }
    }
`;
export const ServiceImageCover = styled(Container)`
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${({ theme }) => theme.color.white};
    text-transform: capitalize;
    font-size: 8vw;
    opacity: 0.6;
    @media screen and (min-width: 576px) { font-size: 1.4rem; };
    @media screen and (min-width: 768px) { font-size: 1.9rem; };
    @media screen and (min-width: 992px) { font-size: 2.6rem; opacity: 0.3; };
    @media screen and (min-width: 1200px) { font-size: 3.1rem; };
    @media screen and (min-width: 1400px) { font-size: 3.6rem; };
    height: 100%;
    transition: .5s ease;
    :hover {
        opacity: 0.8;
    }
`;

// export const TestimonialsCarousel = styled(Carousel)`
    
// `;
export const TestimonialsCarouselCaption = styled(Carousel.Caption)`
    ${media.greaterThan('medium')`
        max-width: 700px;
    `}
    ${media.greaterThan('large')`
        max-width: 800px;
    `}
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    position: initial;
    font-style: italic;
    background-color: ${({ theme }) => theme.color.white};
`;
export const TestimonialsCarouselItem = styled(Carousel.Item)`
    
`;
export const TestimonialsCarouselName = styled.p`
    font-weight: bold;
    text-align: end;
    padding-inline-end: 50px;
`;
export const TestimonialsCarouselText = styled.div`
    display: flex;
    min-height: 400px;
    justify-content: center;
    position: relative;
    padding-block: 50px;
`;
export const TestimonialsComment = styled.div`
    position: relative;
    p {
        font-size: 0.9em;
        ${media.greaterThan('small')`
            font-size: 0.95em;
        `}
        &::before, &::after {
            display: block;
            position: absolute;
            font-size: 80px;
            font-weight: bolder;
            color: ${({theme}) => theme.color.lightgray}
        }
        &::before {
        content: open-quote;
        top: -70px;
        }
    }
    p::after {
        content: close-quote;
        /* bottom: 0; */
        right: 0;
    }
`;
export const TestimonialsQuote = styled.div`
    position: relative;
    padding: 40px;
`;