import React from 'react';
import { FooterInstagramIcon, SiteLink } from '../elements';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Col, Container, Row } from 'react-bootstrap';
import { MdEmail, MdPhone } from 'react-icons/md';
import { ContactForm } from './ContactForm';


export const Footer = ({ location }) => {

  const { file, site, allMarkdownRemark } = useStaticQuery(graphql`
  query FooterQuery {
    file(name: {eq: "Transparent Logo"}) {
      childImageSharp {
        gatsbyImageData(height: 160)
      }
    }
    site {
      siteMetadata {
        contact {
          phone_1
          phone_2
          email
        }
        social {
          instagram
        }
        site_name
      }
    }
    allMarkdownRemark(filter: {frontmatter: {markdown: {eq: "page"}, link: {ne: null}}}) {
      nodes {
        frontmatter {
          title
          link
        }
      }
    }
  }
  `)

  const image = getImage(file)
  const { site_name } = site.siteMetadata
  const { phone_1, phone_2, email } = site.siteMetadata.contact
  const { instagram } = site.siteMetadata.social

  const siteMapItems = allMarkdownRemark.nodes.map(node => {
    return <div key={node.frontmatter.title}><SiteLink to={node.frontmatter.link} selected={node.frontmatter.link === location.pathname}>{node.frontmatter.title}</SiteLink></div>
  })

  return (
    <footer>
      <Container>
        <Row xs={1} md={2} className='py-5 gy-5'>
          <Col>
            <Row className='align-items-end'>
              <Col>
                <Link to='/'><GatsbyImage image={image} alt="logo" /></Link>
              </Col>
              <Col style={{ paddingBlock: '3%' }}>
                <a href={instagram} aria-label='Instagram' alt='Instagram'><FooterInstagramIcon /></a>
              </Col>
            </Row>
            <hr className='border-3 border-top border-white' />
            <Row xs={2} className='mx-auto'>
              {siteMapItems}
            </Row>
            <hr className='border-3 border-top border-white' />
            <Row id='contact' className='flex-column mx-auto text-start gap-2' style={{ maxWidth: '600px' }}>
              <Col>
                <a href={`tel:${phone_1}`}><MdPhone className='m-2 fs-4' /> {phone_1} - Bartek</a>
              </Col>
              <Col>
                <a href={`tel:${phone_2}`}><MdPhone className='m-2 fs-4' />{phone_2} - Stan</a>
              </Col>
              <Col>
                <a href={`mailto:${email}`}><MdEmail className='m-2 fs-4' />{email}</a>
              </Col>
            </Row>
          </Col>
          <Col>
            <ContactForm />
          </Col>
        </Row>
        <Row xs={1} md={2} className='flex-wrap-reverse py-2'>
          <Col className='text-center text-white'>
            © {new Date().getFullYear()} {site_name}. All Rights Reserved.&nbsp;
          </Col>
          <Col className='text-center'>
            <SiteLink to='/terms'>Terms of Use</SiteLink>
            <span className='text-white'>&nbsp;|&nbsp;</span>
            <SiteLink to='/privacy'>Privacy Policy</SiteLink>
          </Col>
        </Row>
      </Container>
    </footer>
  )
};
