import { Link } from "gatsby";
import styled, { css } from "styled-components";
import { FaInstagram } from 'react-icons/fa';


export const FooterInstagramIcon = styled(FaInstagram)`
    width: 30px;
    height: 30px;
    padding: 3px;
    color: ${({ theme }) => theme.color.gray};
    :hover {
        color: ${({ theme }) => theme.color.social_icons};
    }
`;
export const SiteLink = styled(Link)`
    text-transform: capitalize;
    ${(props) => {
        switch (props.selected) {
            case true:
                return css`
                    color: ${({ theme }) => theme.color.hover};
                `;
            default:
                return css`
                    color: ${({ theme }) => theme.color.orange};
                `;
        }
    }}
`;