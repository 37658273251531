import styled from "styled-components";

export const ServicesWrapper = styled.section`
    position: relative;
    #service-container:nth-of-type(2n) {
        /* background-color: ${({ theme }) => theme.color.lightgray}; */
        > div {
            flex-direction: row-reverse;
        }
    }
    .background {
        position: absolute;
        z-index: -1;
        top: 0;
        width: 100%;
        height: 100%;
        /* background: ${({ theme }) => theme.gradient.square_pattern}; */
        background: ${({ theme }) => theme.gradient.cubic_pattern};
        background-size: calc(1.732*82px) 82px;
        opacity: 0.15;
    }
`;