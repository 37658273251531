import { Stack } from 'react-bootstrap';
import styled from 'styled-components';
import media from "styled-media-query";


export const TopHeader = styled(Stack)`
    display: none;
    ${media.greaterThan('medium')`
        display: flex;
        justify-content: flex-end;
        height: 4vh;
        color: ${({ theme }) => theme.color.white};
        background-color: ${({ theme }) => theme.color.black};
    `}
`;