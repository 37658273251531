import React, { useEffect, useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { getImage } from "gatsby-plugin-image"
import { Col, Container, Row } from 'react-bootstrap';
import { FaTimes, FaBars } from 'react-icons/fa';
import { NavLink, NavList, NavListItem, NavListItemSpan, NavLogo, NavLogoLink, NavLogoOnHover, NavToggler } from '../elements';


export const Navbar = ({ location }) => {

  const { allFile, allMarkdownRemark } = useStaticQuery(graphql`
    query NavQuery {
      allFile(filter: {name: {glob: "Icon*"}}) {
        nodes {
          relativePath
          childImageSharp {
            gatsbyImageData(width: 256, layout: CONSTRAINED, placeholder: NONE)
          }
        }
      }
      allMarkdownRemark(filter: {frontmatter: {markdown: {eq: "page"}, link: {ne: null}, title: {ne: "home"}}}) {
        nodes {
          frontmatter {
            title
            link
          }
        }
      }
    }
  `);

  // console.log(allMarkdownRemark.nodes)
  const iconPath = "Icon_Transparent.png"
  const hoverIconPath = "Icon_On_Hover.png"
  const image = getImage(allFile.nodes.find(node => node.relativePath === iconPath))
  const hoverImage = getImage(allFile.nodes.find(node => node.relativePath === hoverIconPath))

  const navListItems = allMarkdownRemark.nodes.map(node => (
    <NavListItem key={node.frontmatter.title}>
      <NavLink to={node.frontmatter.link} selected={node.frontmatter.link === location.pathname}>{node.frontmatter.title}</NavLink>
      <NavListItemSpan />
    </NavListItem>
  ))

  const [click, setClick] = useState(false)

  const handleClick = () => setClick(!click)
  const closeMobileMenu = () => setClick(false)

  // Block page scrolling when nav menu is open
  useEffect(() => {
    click ? document.body.style.overflow = 'hidden' : document.body.style.overflow = 'initial'
  }, [click])

  return (
    <nav>
      <Container fluid="md">
        <Row className='justify-content-around h-100'>
          <Col md={4}>
            <Row className='position-relative'>
              <Col className='position-absolute text-center'>
                <NavLogoLink to="/" onClick={closeMobileMenu} >
                  <NavLogo image={image} />
                  <NavLogoOnHover image={hoverImage} />
                </NavLogoLink>
              </Col>
              <Col>
                <NavToggler click={click} onClick={handleClick}>
                  {click ? <FaTimes /> : <FaBars />}
                </NavToggler>
              </Col>
            </Row>
          </Col>
          <Col md={8} className='p-0'>
            <NavList click={click} onClick={closeMobileMenu} className='d-flex'>
              {navListItems}
            </NavList>
          </Col>
        </Row>
      </Container>
    </nav>
  )
};
