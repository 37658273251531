import React from 'react';
import { Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { ProjectImageCover } from '../elements';


export function Project({ project }) {
    
    const { featuredImage, location, slug } = project.frontmatter

    const image = getImage(featuredImage)
    // console.log(project.frontmatter)
    const projectImage = () => (image && <GatsbyImage image={image} style={{height:'100%'}} alt={location} />)
    
    return (
        <Link to={'/projects/'+slug} className='position-relative d-flex'>
            {projectImage()}
            <ProjectImageCover>
                {location}
            </ProjectImageCover>
        </Link>
    )
};
