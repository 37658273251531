import React from 'react'
import { Navbar } from './';
import { TopHeader } from '../elements';
import { graphql, useStaticQuery } from 'gatsby';
import { MdOutlineEmail, MdPhone } from 'react-icons/md';


export const Header = ({ location }) => {

  const data = useStaticQuery(graphql`
  query HeaderQuery {
      site {
        siteMetadata {
          contact {
            phone_1
            phone_2
            email
          }
        }
      }
    }
  `);

  const { email, phone_1, phone_2 } = data.site.siteMetadata.contact;

  return (
    <header>
      <TopHeader className='px-4' gap={3} direction="horizontal">
        <div>
          <a href={`tel:${phone_1}`}>
            <MdPhone className='m-2 fs-4' />
            Bartek - {phone_1}
          </a>
        </div>
        <div className="vr" />
        <div>
          <a href={`tel:${phone_2}`}>
            <MdPhone className='m-2 fs-4' />
            Stan - {phone_2}
          </a>
        </div>
        <div className="vr" />
        <div>
          <a href={`mailto:${email}`}>
            <MdOutlineEmail className='m-2 fs-4' />
            {email}
          </a>
        </div>
      </TopHeader>
      <Navbar location={location} />
    </header>
  )
};
