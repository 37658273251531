import React from 'react';
import { BannerImage, BannerInnerRow, BannerTextContainer, BannerTextWrapper } from '../elements';
import { Col, Container } from 'react-bootstrap';
import { getImage } from 'gatsby-plugin-image';


export function Banner({ title, featuredImage }) {

  const image = getImage(featuredImage)

  return (
    <Container fluid>
      <BannerInnerRow>
        <BannerImage image={image} loading='eager' alt='hero image' />
        <BannerTextWrapper>
          <BannerTextContainer>
            <Col>
              {title}
            </Col>
          </BannerTextContainer>
        </BannerTextWrapper>
      </BannerInnerRow>
    </Container>
  )
};
