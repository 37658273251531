import styled, { keyframes } from 'styled-components';
import { Button } from '../components/controls';
import { GatsbyImage } from 'gatsby-plugin-image';


export const BtnOutlined = styled(Button)`
    --bs-btn-bg: transparent;
    --bs-btn-border-width: 2px;
    --bs-btn-font-weight: 600;
    text-transform: capitalize;
`;
export const BtnOutlinedDark = styled(BtnOutlined)`
    --bs-btn-color: ${({ theme }) => theme.color.orange};
    --bs-btn-border-color: ${({ theme }) => theme.color.orange};
    --bs-btn-hover-color: ${({ theme }) => theme.color.dark_blue};
    --bs-btn-hover-bg: ${({ theme }) => theme.color.orange};
    --bs-btn-hover-border-color: ${({ theme }) => theme.color.dark_blue};
    --bs-btn-active-color: ${({ theme }) => theme.color.black};
    --bs-btn-active-bg: ${({ theme }) => theme.color.white};
    --bs-btn-active-border-color: ${({ theme }) => theme.color.white};
`;
export const BtnOutlinedLight = styled(BtnOutlined)`
    --bs-btn-color: ${({ theme }) => theme.color.dark_blue};
    --bs-btn-border-color: ${({ theme }) => theme.color.dark_blue};
    --bs-btn-hover-color: ${({ theme }) => theme.color.white};
    --bs-btn-hover-bg: ${({ theme }) => theme.color.dark_blue};
    --bs-btn-hover-border-color: ${({ theme }) => theme.color.white};
    --bs-btn-active-color: ${({ theme }) => theme.color.white};
    --bs-btn-active-bg: ${({ theme }) => theme.color.black};
    --bs-btn-active-border-color: ${({ theme }) => theme.color.black};
`;

export const ComponentTitle = styled.div`
    padding: clamp(15px, 4vw, 50px);
`;
export const LayoutWrapper = styled.div`
    min-height: 100vh;
    display: grid;
    grid-template-rows: auto 1fr auto;
`;
const loaderOpacity = keyframes`
    from {opacity: 0;} to {opacity: 1;}
`;
export const LoaderText = styled.span`
    color: ${({ theme }) => theme.color.orange};
    animation: ${loaderOpacity} 2s ease-in-out .3s both;
`;
export const LoaderLogo = styled(GatsbyImage)`
    width: calc(110px + 1vw);
    animation: ${loaderOpacity} 2s ease-in-out .3s both;
`;
const loaderWrapper = keyframes`
    from {top: 0;}
    to {top: -100vh;}
`;
export const LoaderWrapper = styled.div`
    width: 100%;
    height: 100vh;
    position: fixed;
    left: 0;
    /* display: flex; */
    justify-content: center;
    align-items: center;
    background-color: ${({ theme }) => theme.color.dark_blue};
    z-index: 11;
    animation: ${loaderWrapper} 2s ease-in-out 2s both;
`;
export const SectionBlue = styled.section`
    background: ${({ theme }) => theme.color.dark_blue};
    color: ${({ theme }) => theme.color.white};
`;