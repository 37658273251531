import React from 'react';
import { Button as ButtonBS } from 'react-bootstrap';


export function Button({ text, onClick, ...other }) {

    return (
        <ButtonBS
        onClick={onClick}
        {...other}>
            {text}
        </ButtonBS>
    )
};