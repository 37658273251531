// import BackgroundImage from "gatsby-background-image";
import { GatsbyImage } from "gatsby-plugin-image";
import { Container, Row } from "react-bootstrap";
import styled, { keyframes } from "styled-components";
import media from "styled-media-query";


export const BannerImage = styled(GatsbyImage)`
    height: 84vh;
    ${media.greaterThan('medium')`
        height: 80vh;
    `}
`;
export const BannerInnerRow = styled(Row)`
    justify-content: center;
    align-items: center;
`;
const bannerTextAnimation = keyframes`
    33% { opacity: 0; visibility: hidden; }
    66% { filter: blur(12px) opacity(0); }
    100% { filter: blur(0) opacity(1); visibility: visible; }
`;
export const BannerTextContainer = styled(Row)`
    align-items: center;
    color: ${props => props.color || 'white'};
    text-align: center;
    text-transform: capitalize;
    font-size: 1.5em;
    height: 100%;
    visibility: hidden;
    animation: ${bannerTextAnimation} 4s forwards;
    ${media.greaterThan('small')`
        font-size: 2.1em;
    `}
    ${media.greaterThan('medium')`
        font-size: 3em;    
    `}
`;
const largeWrapperAnim = keyframes`
    0% { border: solid wheat; }
    100% { width: 560px; border: solid wheat; }
`;
const mediumWrapperAnim = keyframes`
    0% { border: solid wheat; }
    100% { width: 400px; border: solid wheat; }
`;
const smallWrapperAnim = keyframes`
    0% { border: solid wheat; }
    100% { width: 290px; border: solid wheat; }
`;
export const BannerTextWrapper = styled(Container)`
    position: absolute;
    margin: auto;
    background-color: ${({ theme }) => theme.color.dark_gray};
    opacity: 0.8;
    width: 0; /* required to start the animation */
    height: 75px;
    animation: ${smallWrapperAnim} 2s 1s forwards;
    ${media.greaterThan('small')`
        height: 105px;
        animation: ${mediumWrapperAnim} 2s 1s forwards;
    `}
    ${media.greaterThan('medium')`
        height: 150px;
        animation: ${largeWrapperAnim} 2s 1s forwards;
    `}
`;