import { Container, ListGroupItem } from "react-bootstrap";
import styled, { css } from "styled-components";


export const FilterListItem = styled(ListGroupItem)`
    ${(props) => {
        switch (props.selected) {
            case true:
                return css`
                    color: ${({ theme }) => theme.color.hover};
                `;
            default:
                return css`
                    color: ${({ theme }) => theme.color.orange};
                `;
        }
    }}
    background-color: transparent;
`;
export const ProjectImageCover = styled(Container)`
    position: absolute;
    display: flex;
    align-items: end;
    justify-content: start;
    text-transform: capitalize;
    font-size: 8vw;
    opacity: 0.8;
    @media (min-width: 576px) { font-size: 1.4rem; };
    @media (min-width: 768px) { font-size: 1.9rem; };
    @media (min-width: 992px) { font-size: 1.7rem; opacity: 0; };
    @media (min-width: 1200px) { font-size: 2rem; };
    @media (min-width: 1400px) { font-size: 2.4rem; };
    padding: 0.4em 0.8em;
    height: 100%;
    color: ${({ theme }) => theme.color.white};
    background-image: ${({ theme }) => theme.gradient.dark_blue};
    transition: .5s ease;
    :hover {
        opacity: 1;
    }
`;